<section class="main-slider">
    <div class="container-fluid">
        <div class="row">
            <div class="col p-0">
                <app-carousel-holder></app-carousel-holder>
            </div>            
        </div>
    </div>  
    <div class="clearfix"></div>
</section>

<section class="padding-y hom_intro">
    <div class="container">
        <div class="row">
            <div class="col text-center hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__zoomIn">
                <h2 class="box-heading">Raw Virgin Indian Human Hair</h2>
            </div>
        </div>
        <div class="row mt-4">

            <div class="col-md-4 col-xs-12">
                <div class="hom_intro_info hide-on-init animate__slow" animateOnScroll animationName="animate__animated animate__zoomIn">
                    <h3>
                        Naturally Gorgeous
                    </h3>
                    <p>Fearless femininity and beautiful bravery. It's an unstoppable combination. With over 20 years of expert industry experience. Priyanka Swamy hand-</p>
                    <iframe width="100%" height="300" src="https://www.youtube.com/embed/t53KZzsFEBQ" title="SGI Hair" frameborder="0" allowfullscreen></iframe>
                    <a href="#">Learn More</a>
                </div>
            </div>

            <div class="col-md-8 col-xs-12">
                <div class="row">
                    <div class="col-md-6 col-xs-12">
                        <div class="hom_intro_pic hide-on-init animate__slow" animateOnScroll animationName="animate__animated animate__zoomIn">
                            <img src="assets/images/directly-sourced.jpg" alt="" class="img-fluid">
                            <h4 class="title">Directly Sourced</h4>
                        </div>
                    </div>
                    <div class="col-md-6 col-xs-12">
                        <div class="hom_intro_pic hide-on-init animate__slow" animateOnScroll animationName="animate__animated animate__zoomIn">
                            <img src="assets/images/production-house.jpg" alt="" class="img-fluid">
                            <h4 class="title">Production house at heart of Chennai</h4>
                        </div>
                    </div>
                    
                    <div class="col-md-6 col-xs-12">
                        <div class="hom_intro_pic hide-on-init animate__slow" animateOnScroll animationName="animate__animated animate__zoomIn">
                            <img src="assets/images/secured-payment.jpg" alt="" class="img-fluid">
                            <h4 class="title">Wholesale cart with secured payment</h4>
                        </div>
                    </div>
                    <div class="col-md-6 col-xs-12">
                        <div class="hom_intro_pic hide-on-init animate__slow" animateOnScroll animationName="animate__animated animate__zoomIn">
                            <img src="assets/images/ocean-air1.jpg" alt="" class="img-fluid">
                            <h4 class="title">Ocean and air shipping</h4>
                        </div>
                    </div>
                </div>
            </div>

            <!--<div class="col-md-12 col-xs-12 text-center">
                <div class="button">
                    <a href="#" class="text-black">Read More</a>
                </div>
            </div>-->

        </div>
        
    </div>
</section>

<!--<section class="padding-y hom_intro">
    <div class="container">
        <div class="row">
            <div class="col text-center hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__zoomIn">
                <h2 class="box-heading">Raw Virgin Indian Human Hair</h2>
                <h5>Experience matters. We've been producing gorgeous hair that exceeds your expectations since 2008.</h5>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-4 col-xs-12">
                <div class="icon-wrapper hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__fadeInLeft">
                    <div class="icon icon-img">
                        <img src="assets/images/png/years-tag.png" alt="" class="img-sm img-fluid">
                    </div>
                    <div class="icon-content text">
                        <h4 class="title">Industry Experts</h4>
                        <p>Experience matters. We've been producing gorgeous hair that exceeds your expectations since 2008.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-xs-12">
                <div class="icon-wrapper hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__zoomIn">
                    <div class="icon icon-img">
                         <img src="assets/images/png/source-tag.png" alt="" class="img-sm img-fluid">
                    </div>
                    <div class="text icon-content">
                        <h4 class="title">Ethically Sourced</h4>
                        <p>Experience matters. We've been producing gorgeous hair that exceeds your expectations since 2008.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-xs-12">
                <div class="icon-wrapper hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__fadeInRight">
                    <div class="icon icon-img">
                         <img src="assets/images/png/price-tag.png" alt="" class="img-sm img-fluid">
                    </div>
                    <div class="text icon-content">
                        <h4 class="title">Wholesale Price</h4>
                        <p>Experience matters. We've been producing gorgeous hair that exceeds your expectations since 2008.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-xs-12 text-center">
                <div class="button">
                    <a href="#" class="text-black">Read More</a>
                </div>
            </div>

        </div>
        <div class="row mt-3">
            <div class="col-md-4">
                <div class="box">
                    <div class="box-image-caption">
                        <div class="box-image-wrapper aligncenter">
                            <img src="assets/images/models/img9.jpg" alt="" class="img-fluid">
                        </div>
                        <div class="box-image-content">
                            <div class="box-image-title">
                                <h6>Ind</h6>
                            </div>
                            <p>STEP 1</p>
                        </div>
                    </div>
                    <div class="box-content mt-3">
                        <h4 class="aligncenter">SOURCING</h4>
                        <p>The TirumalaTirupatiDevasthanam (TTD) that looks after the affairs of the richest temple in the country in Tirumala has collected revenue of Rs 7.94 cr / 1,135,405 USD through e-auction</p>
                    </div>
                </div>                
            </div>
            <div class="col-md-4">
                <div class="box">
                    <div class="box-image-caption">
                        <div class="box-image-wrapper">
                            <img src="assets/images/models/img10.jpg" alt="" class="img-fluid">
                        </div>
                        <div class="box-image-content">
                            <div class="box-image-title">
                                <h6>OUR PROCESS</h6>
                            </div>
                            <p>STEP 2</p>
                        </div>
                    </div>
                    <div class="box-content mt-3">
                        <h4 class="aligncenter">OUR PROCESS</h4>
                        <p>Our are hand-crafted with love, we pre-condition, washing, and conditioning all hair not once, but twice - Quality is paramount. We built a multi-step quality control for wonderful customers like you…!.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box">
                    <div class="box-image-caption">
                        <div class="box-image-wrapper">
                            <img src="assets/images/models/img11.jpg" alt="" class="img-fluid">
                        </div>
                        <div class="box-image-content">
                            <div class="box-image-title">
                                <h6>OUR PROMISE</h6>
                            </div>
                            <p>STEP 3</p>
                        </div>
                    </div>
                    <div class="box-content mt-3">
                        <h4 class="aligncenter">OUR PROMISE</h4>
                        <p>Our full-circle experience gives you support before and after your purchase. Our dedicated team of hair professionals is passionate about hair and here to serve you. You'll feel safe with us…!.</p>
                    </div>
                </div>
            </div>
        </div>  
    </div>
</section>-->

<!--Hair Products-->
<section class="padding-y hom_products_all">
    <div class="container">
        <div class="row">
            <div class="col text-center  hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__zoomIn">
                <h2 class="box-heading">SGI Hair Products</h2>
                <h5>Giving Confidence Through Gorgeous Hair</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-xs-12 hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__fadeInLeft">
                <div class="hom_products">
                    <a href="#">
                        <div class="hom_product_pic">
                            <img src="assets/images/hom-products/machine-weft.jpg" alt="Machine Weft" class="img-fluid">
                        </div>
                        <div class="hom_product_content">
                            <h3><span>Machine Weft</span></h3>
                        </div>
                    </a>    
                </div>
            </div>
            <div class="col-md-4 col-xs-12 hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__zoomIn">
                <div class="hom_products">
                    <a href="#">
                        <div class="hom_product_pic">
                            <img src="assets/images/hom-products/bulk-hair.jpg" alt="Bulk Hair" class="img-fluid">
                        </div>
                        <div class="hom_product_content">
                            <h3><span>Bulk Hair</span></h3>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-md-4 col-xs-12 hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__fadeInRight">
                <div class="hom_products">
                    <a href="#">
                        <div class="hom_product_pic">
                            <img src="assets/images/hom-products/clip-in-hair-extension.jpg" alt="Lace Closures" class="img-fluid"> <!--assets/images/hom-products/lace-closures.jpg-->
                        </div>
                        <div class="hom_product_content">
                            <h3><span>Lace Closures</span></h3>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-md-4 col-xs-12 hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__fadeInLeft">
                <div class="hom_products">
                    <a href="#">
                        <div class="hom_product_pic">
                            <img src="assets/images/hom-products/silk-base-closures.jpg" alt="Silk Base Closures" class="img-fluid">
                        </div>
                        <div class="hom_product_content">
                            <h3><span>Silk Base Closures</span></h3>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-md-4 col-xs-12 hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__zoomIn">
                <div class="hom_products">
                    <a href="#">
                        <div class="hom_product_pic">
                            <img src="assets/images/hom-products/lace-frontals.jpg" alt="Lace Frontal's" class="img-fluid">
                        </div>
                        <div class="hom_product_content">
                            <h3><span>Lace Frontal's</span></h3>
                        </div>
                    </a>    
                </div>
            </div>
            <div class="col-md-4 col-xs-12 hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__fadeInRight">
                <div class="hom_products">
                    <a href="#">
                        <div class="hom_product_pic">
                            <img src="assets/images/hom-products/full-lace-wigs.jpg" alt="Full Lace Wig's" class="img-fluid">
                        </div>
                        <div class="hom_product_content">
                            <h3><span>Full Lace Wig's</span></h3>
                        </div>
                    </a>
                </div>
            </div>

            <!--<div class="col-md-4 col-xs-12 hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__fadeInLeft">
                <div class="hom_products">
                    <a href="#">
                        <div class="hom_product_pic">
                            <img src="assets/images/hom-products/front-lace-wigs.jpg" alt="Front Lace wig's" class="img-fluid">
                        </div>
                        <div class="hom_product_content">
                            <h3><span>Front Lace wig's</span></h3>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-md-4 col-xs-12 hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__zoomIn">
                <div class="hom_products">
                    <a href="#">
                        <div class="hom_product_pic">
                            <img src="assets/images/hom-products/clip-in-hair-extension.jpg" alt="Clip in hair extension" class="img-fluid">
                        </div>
                        <div class="hom_product_content">
                            <h3><span>Clip in hair extension</span></h3>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-md-4 col-xs-12 hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__fadeInRight">
                <div class="hom_products">
                    <a href="#">
                        <div class="hom_product_pic">
                            <img src="assets/images/hom-products/360-lace-frontals.jpg" alt="360 Lace Frontals" class="img-fluid">
                        </div>
                        <div class="hom_product_content">
                            <h3><span>360 Lace Frontals</span></h3>
                        </div>
                    </a>
                </div>
            </div>-->


        </div>
    </div>
</section>
<!--Hair Products-->

<!--Hair Products-->
<!--<section class="padding-y hom_products_all">
    <div class="container">
        <div class="row">
            <div class="col text-center  hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__zoomIn">
                <h2 class="box-heading">Shop by Category</h2>
                <h5>We are leading Manufacturers & Exporters Natural Human Hair Wigs and Hair Extension Supplier in Chennai India. We are the Wholesale Distributors of Virgin Indian Human Hair, Weave, Curly Blond, Long hair, Lace Closures, Lace Frontals, Front Lace Wigs, Pre-Bonded Hair for more apply online.</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-xs-12 hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__fadeInLeft">
                <div class="hom_products">
                    <div class="hom_product_pic">
                        <img src="assets/images/hom-products/machine-weft.jpg" alt="Machine Weft" class="img-fluid">
                    </div>
                    <div class="hom_product_content">
                        <h3>Machine Weft</h3>
                        <p>Our Indian hair weaves made by 100% Virgin Indian unprocessed raw Remy human hair. This Indian hair weave double weft and sealed with transparent glue to make sure its very strong to stop shedding.</p>
                        <a href="#">Learn More</a>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-xs-12 hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__zoomIn">
                <div class="hom_products">
                    <div class="hom_product_pic">
                        <img src="assets/images/hom-products/bulk-hair.jpg" alt="Bulk Hair" class="img-fluid">
                    </div>
                    <div class="hom_product_content">
                        <h3>Bulk Hair</h3>
                        <p>Bulk hair means no weft its used to create hair extensions and to make wigs, closures and all other types of hair extensions This hair origin from of India shaved from single donors head generally.</p>
                        <a href="#">Learn More</a>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-xs-12 hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__fadeInRight">
                <div class="hom_products">
                    <div class="hom_product_pic">
                        <img src="assets/images/hom-products/lace-closures.jpg" alt="Lace Closures" class="img-fluid">
                    </div>
                    <div class="hom_product_content">
                        <h3>Lace Closures</h3>
                        <p>Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.</p>
                        <a href="#">Learn More</a>
                    </div>
                </div>
            </div>

            <div class="col-md-4 col-xs-12 hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__fadeInLeft">
                <div class="hom_products">
                    <div class="hom_product_pic">
                        <img src="assets/images/hom-products/silk-base-closures.jpg" alt="Silk Base Closures" class="img-fluid">
                    </div>
                    <div class="hom_product_content">
                        <h3>Silk Base Closures</h3>
                        <p>Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.</p>
                        <a href="#">Learn More</a>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-xs-12 hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__zoomIn">
                <div class="hom_products">
                    <div class="hom_product_pic">
                        <img src="assets/images/hom-products/lace-frontals.jpg" alt="Lace Frontal's" class="img-fluid">
                    </div>
                    <div class="hom_product_content">
                        <h3>Lace Frontal's</h3>
                        <p>Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.</p>
                        <a href="#">Learn More</a>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-xs-12 hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__fadeInRight">
                <div class="hom_products">
                    <div class="hom_product_pic">
                        <img src="assets/images/hom-products/full-lace-wigs.jpg" alt="Full Lace Wig's" class="img-fluid">
                    </div>
                    <div class="hom_product_content">
                        <h3>Full Lace Wig's</h3>
                        <p>Full lace wigs for women made with Swiss lace base cap and we used 100% Indian Remy human hair each hair strands goes to the very tiny lace wholes and carefully knotted using wig hooks.</p>
                        <a href="#">Learn More</a>
                    </div>
                </div>
            </div>

            <div class="col-md-4 col-xs-12 hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__fadeInLeft">
                <div class="hom_products">
                    <div class="hom_product_pic">
                        <img src="assets/images/hom-products/front-lace-wigs.jpg" alt="Front Lace wig's" class="img-fluid">
                    </div>
                    <div class="hom_product_content">
                        <h3>Front Lace wig's</h3>
                        <p>Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.</p>
                        <a href="#">Learn More</a>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-xs-12 hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__zoomIn">
                <div class="hom_products">
                    <div class="hom_product_pic">
                        <img src="assets/images/hom-products/clip-in-hair-extension.jpg" alt="Clip in hair extension" class="img-fluid">
                    </div>
                    <div class="hom_product_content">
                        <h3>Clip in hair extension</h3>
                        <p>Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.</p>
                        <a href="#">Learn More</a>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-xs-12 hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__fadeInRight">
                <div class="hom_products">
                    <div class="hom_product_pic">
                        <img src="assets/images/hom-products/360-lace-frontals.jpg" alt="360 Lace Frontals" class="img-fluid">
                    </div>
                    <div class="hom_product_content">
                        <h3>360 Lace Frontals</h3>
                        <p>Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.</p>
                        <a href="#">Learn More</a>
                    </div>
                </div>
            </div>


        </div>
    </div>
</section>-->
<!--Hair Products-->

<!-- <section class="full-width collection-sec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 p-0 text-white">
                <div class="inner-collection p-5">
                    <h2>Mens Hair Collection</h2>
                    <h4 class="mb-3">Healthy hair that looks attractive can make you feel confident and super attractive. Shiny and Strong hair makes you the cynosure of all eyes.</h4>
                    <p class="mb-3">
                        Taking a few basic hair care steps go a long way in maintaining your hair. Otherwise, hair fall, unhealthy hair, and lackluster hair is the end result that can well be avoided. Remember, your hair is an index of your personality which can make or mar your looks.
                    </p>
                    <div class="button">
                        <a href="" class="filled-outline">More Collection</a>
                    </div>
                    
                </div>
                
            </div>
            <div class="col-md-6 p-0 bg-white">
                <div class="inner-collection p-5">
                    <h2>Womens Hair Collection</h2>
                    <h4 class="mb-3">Healthy hair that looks attractive can make you feel confident and super attractive. Shiny and Strong hair makes you the cynosure of all eyes.</h4>
                    <p class="mb-3">
                        Taking a few basic hair care steps go a long way in maintaining your hair. Otherwise, hair fall, unhealthy hair, and lackluster hair is the end result that can well be avoided. Remember, your hair is an index of your personality which can make or mar your looks.
                    </p>
                    <div class="button">
                        <a href="" class="filled-outline text-black">More Collection</a>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</section> -->
<!--<section class="product-block padding-y">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="product-tabs">
                    <h2 class="box-heading text-center">Top Products</h2>
                    <ul ngbNav #nav="ngbNav" [(activeId)]="active" [destroyOnHide]="false" class="nav-tabs justify-content-center">
                      <li [ngbNavItem]="1">
                        <a ngbNavLink>Machine Weft</a>
                        <ng-template ngbNavContent >
                            <div class="row">
                                <div class="container">
                                    <owl-carousel-o [options]="productTabOptions" (initialized)="getData($event)" class="row">
                                            <ng-template class="slide" carouselSlide *ngFor="let product of products">                                   
                                                <app-product-carousel [product]="product"></app-product-carousel>                                    
                                            </ng-template>
                                       
                                    </owl-carousel-o>
                                   
                                 </div> 
                             </div>                                                  
                        </ng-template>
                      </li>
                      <li [ngbNavItem]="2">
                        <a ngbNavLink>Full lace wig's</a>
                        <ng-template ngbNavContent>
                            <owl-carousel-o [options]="productTabOptions" (initialized)="getData($event)" class="row">
                                    <ng-template class="slide" carouselSlide *ngFor="let product of products">                                   
                                        <app-product-carousel [product]="product"></app-product-carousel>                                    
                                    </ng-template>
                               
                            </owl-carousel-o>
                        </ng-template>
                      </li>
                      <li [ngbNavItem]="3">
                        <a ngbNavLink>Lace closures</a>
                        <ng-template ngbNavContent>
                            <owl-carousel-o [options]="productTabOptions">
                                <ng-template class="slide" carouselSlide *ngFor="let product of products">
                                   
                                    <app-product-carousel [product]="product"></app-product-carousel>
                                    
                                </ng-template>
                            </owl-carousel-o>
                        </ng-template>
                      </li>
                      <li [ngbNavItem]="4">
                        <a ngbNavLink>Lace Frontal's</a>
                        <ng-template ngbNavContent>
                            <owl-carousel-o [options]="productTabOptions4" (translated)="getData($event)">
                                <ng-template class="slide" carouselSlide *ngFor="let product of products">
                                   
                                    <app-product-carousel [product]="product"></app-product-carousel>
                                    
                                </ng-template>
                            </owl-carousel-o>
                        </ng-template>
                      </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="mt-5"></div>
                    
                </div>
            </div>
        </div>
    </div>
</section>-->

<!--<section class="promo-banner-block padding-y">
    <div class="container">
        <div class="fullwidth-promo-banner">
            <div class="row justify-content-start align-item-start">
                <div class="imgbanner col firstbanner">
                    <div class=" banner-effect">
                        <a href="#">
                            <img src="assets/images/banner/banner.jpg" alt="banner" class="img-fluid">
                        </a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>-->
<!--<section class="padding-y ">
    <div class="container">
        <div class="row">
            <div class="col text-center">
                <h2 class="box-heading">Featured Products</h2>
                <h5>Assert your feminity with confidence using Triss Hair care Products</h5>
            </div>
        </div>
        <div class="row mt-4">            
            <div class="col">
                <owl-carousel-o [options]="productFeature" class="row">
                    <ng-template class="slide" carouselSlide *ngFor="let product of products">
                       <app-product-carousel [product]="product"></app-product-carousel>
                    </ng-template>
                </owl-carousel-o>
                
            </div>
            
        </div>
    </div>
</section>-->

<section class="promo-banner-block padding-y">
    <div class="container">
        <div class="fullwidth-promo-banner">
            <div class="row justify-content-start align-item-start">
                <div class="imgbanner col firstbanner">
                    <div class=" banner-effect hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__zoomIn">
                        <a href="#">
                            <img src="assets/images/banner/banner-4.jpg" alt="banner" class="img-fluid">
                        </a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<!--Popular Hot-->
<section class="padding-y hom_popular_hot">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-xs-12"> <!--col-md-6 col-xs-12-->
                <div class="col hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__zoomIn">
                    <h2 class="box-heading ">Popular Products</h2>
                </div>
                    
                <div class="col  hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__zoomIn">
                    <owl-carousel-o [options]="productPopular" class="row">
                        <ng-template class="slide" carouselSlide *ngFor="let product of products">
                        <app-product-carousel [product]="product"></app-product-carousel>
                        </ng-template>
                    </owl-carousel-o>

                    <!--<div class="s_more">
                        <a href="#">See More</a>
                    </div>-->
                    
                </div>
            </div>

            <!--<div class="col-md-6 col-xs-12">
                <div class="col hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__zoomIn">
                    <h2 class="box-heading ">Hot Wig's</h2>
                </div>           
                <div class="col  hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__zoomIn">
                    <owl-carousel-o [options]="productHotwig" class="row">
                        <ng-template class="slide" carouselSlide *ngFor="let product of products">
                        <app-product-carousel [product]="product"></app-product-carousel>
                        </ng-template>
                    </owl-carousel-o>

                    <div class="s_more">
                        <a href="#">See More</a>
                    </div>
                    
                </div>
            </div>-->

        </div>    
    </div>
</section>
<!--Popular Hot-->

<!--<section class="padding-y hom_bestsellers">
    <div class="container">
        <div class="row">
            <div class="col text-center hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__zoomIn">
                <h2 class="box-heading ">Wholesale Deal</h2>
                <h5>Something for you</h5>
            </div>
        </div>
        <div class="row mt-4">            
            <div class="col  hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__zoomIn">
                <owl-carousel-o [options]="productBestseller" class="row">
                    <ng-template class="slide" carouselSlide *ngFor="let product of products">
                       <app-product-carousel [product]="product"></app-product-carousel>
                    </ng-template>
                </owl-carousel-o>
                
            </div>
            
        </div>
    </div>
</section>-->
<!--<section class="padding-y">
    <div class="container">
        <div class="row">
            <div class="col text-center">
                <h2 class="box-heading">Wholesale SampleKit</h2>
                <h5>Assert your feminity with confidence using Triss Hair care Products</h5>
            </div>
        </div>
        <div class="row mt-4">            
            <div class="col">
                <owl-carousel-o [options]="productFeature" class="row">
                    <ng-template class="slide" carouselSlide *ngFor="let product of products">
                       <app-product-carousel [product]="product"></app-product-carousel>
                    </ng-template>
                </owl-carousel-o>
                
            </div>
            
        </div>
    </div>
</section>-->
<!-- <section class="padding-y model-sec">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="inner-collection text-white">
                    <h2>Our Hair Products</h2>
                    <h4 class="mb-3">Healthy hair that looks attractive can make you feel confident and super attractive. Shiny and Strong hair makes you the cynosure of all eyes.</h4>
                    <p class="mb-3">
                        Taking a few basic hair care steps go a long way in maintaining your hair. Otherwise, hair fall, unhealthy hair, and lackluster hair is the end result that can well be avoided. Remember, your hair is an index of your personality which can make or mar your looks.
                    </p>
                    <div class="button">
                        <a href="" class="filled-outline text-black">More Collection</a>
                    </div>
                </div>
                
            </div>
            <div class="col-md-6 flipbox">
                <div class="row">
                    <div class="col-md-4">
                        <div class="flip-container mb-3">
                            <div class="flip-card">
                                <div class="card-front flip" style="background-image:url('assets/images/product/product2.jpg');"></div>
                                <div class="card-back flip">
                                    <div class="inner-content">
                                        <h5>Machine Weft</h5>
                                        <h4>$ 150/-</h4>
                                        <h6>3 Months</h6>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                    <div class="col-md-4">
                        <div class="flip-container mb-3">
                            <div class="flip-card">
                                <div class="card-front flip"  style="background-image:url('assets/images/product/product15.jpg');"></div>
                                <div class="card-back flip">
                                    <div class="inner-content">
                                        <h5>Bulk Hair</h5>
                                        <h4>$ 150/-</h4>
                                        <h6>3 Months</h6>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div class="col-md-4">
                        <div class="flip-container mb-3">
                            <div class="flip-card">
                                <div class="card-front flip" style="background-image:url('assets/images/models/img9.jpg');"></div>
                                <div class="card-back flip">
                                    <div class="inner-content">
                                        <h5>Lace Closures</h5>
                                        <h4>$ 150/-</h4>
                                        <h6>3 Months</h6>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>                        
                    </div>
                    <div class="col-md-4">
                        <div class="flip-container mb-3">
                            <div class="flip-card">
                                <div class="card-front flip"  style="background-image:url('assets/images/models/img11.jpg');"></div>
                                <div class="card-back flip">
                                    <div class="inner-content">
                                        <h5>Silk Base Closures</h5>
                                        <h4>$ 150/-</h4>
                                        <h6>3 Months</h6>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div class="col-md-4">
                        <div class="flip-container mb-3">
                            <div class="flip-card">
                                <div class="card-front flip"  style="background-image:url('assets/images/models/img11.jpg');"></div>
                                <div class="card-back flip">
                                    <div class="inner-content">
                                        <h5>Lace Frontal's</h5>
                                        <h4>$ 150/-</h4>
                                        <h6>3 Months</h6>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div class="col-md-4">
                        <div class="flip-container mb-3">
                            <div class="flip-card">
                                <div class="card-front flip"  style="background-image:url('assets/images/models/img11.jpg');"></div>
                                <div class="card-back flip">
                                    <div class="inner-content">
                                        <h5>Full Lace Wig's</h5>
                                        <h4>$ 150/-</h4>
                                        <h6>3 Months</h6>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- <section class="padding-y">
    <div class="container">
        <div class="row">
            <div class="col text-center">
                <h2>Our Programs</h2>
                <h5>Make a difference in the life of an individual...</h5>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-4">
                <div class="box">
                    <div class="box-image-caption">
                        <div class="box-image-wrapper aligncenter">
                            <img src="assets/images/models/img9.jpg" alt="" class="img-fluid">
                        </div>
                        <div class="box-image-content">
                            <div class="box-image-title">
                                <h6>SOURCING</h6>
                            </div>
                            <p>STEP 1</p>
                        </div>
                    </div>
                    <div class="box-content mt-3">
                        <h4 class="aligncenter">SOURCING</h4>
                        <p>The TirumalaTirupatiDevasthanam (TTD) that looks after the affairs of the richest temple in the country in Tirumala has collected revenue of Rs 7.94 cr / 1,135,405 USD through e-auction</p>
                    </div>
                </div>                
            </div>
            <div class="col-md-4">
                <div class="box">
                    <div class="box-image-caption">
                        <div class="box-image-wrapper">
                            <img src="assets/images/models/img10.jpg" alt="" class="img-fluid">
                        </div>
                        <div class="box-image-content">
                            <div class="box-image-title">
                                <h6>OUR PROCESS</h6>
                            </div>
                            <p>STEP 2</p>
                        </div>
                    </div>
                    <div class="box-content mt-3">
                        <h4 class="aligncenter">OUR PROCESS</h4>
                        <p>Our are hand-crafted with love, we pre-condition, washing, and conditioning all hair not once, but twice - Quality is paramount. We built a multi-step quality control for wonderful customers like you…!.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box">
                    <div class="box-image-caption">
                        <div class="box-image-wrapper">
                            <img src="assets/images/models/img11.jpg" alt="" class="img-fluid">
                        </div>
                        <div class="box-image-content">
                            <div class="box-image-title">
                                <h6>OUR PROMISE</h6>
                            </div>
                            <p>STEP 3</p>
                        </div>
                    </div>
                    <div class="box-content mt-3">
                        <h4 class="aligncenter">OUR PROMISE</h4>
                        <p>Our full-circle experience gives you support before and after your purchase. Our dedicated team of hair professionals is passionate about hair and here to serve you. You'll feel safe with us…!.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<section class="promo-banner-block padding-y">
    <div class="container">
        <div class="fullwidth-promo-banner">
            <div class="row justify-content-start align-item-start">
                <div class="imgbanner col firstbanner">                    
                    <div class=" banner-effect  hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__zoomIn">
                        <a href="#">
                            <img src="assets/images/banner/banner-3.jpg" alt="banner" class="img-fluid">
                        </a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<!--Wholesale Hot Wigs sample Kit-->
<section class="padding-y hom_wholesale_samplekit hom_whs_short">
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-xs-12">
                <div class="col hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__zoomIn">
                    <h2 class="box-heading">Wholesale Deal</h2>
                </div>           
                <div class="col hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__zoomIn">
                    <div class="row">
                        <div class="col-md-6 col-xs-6">
                            <div class="whs_short">
                                <img src="assets/images/product/product2.jpg" alt="" class="img-fluid">
                                <h3><a href="#">4"4 Closure Wigs</a></h3>
                            </div>
                        </div>
                        <div class="col-md-6 col-xs-6">
                            <div class="whs_short">
                                <img src="assets/images/product/product15.jpg" alt="" class="img-fluid">
                                <h3><a href="#">4"4 Closures</a></h3>
                            </div>
                        </div>
                        <div class="col-md-6 col-xs-6">
                            <div class="whs_short">
                                <img src="assets/images/product/product15.jpg" alt="" class="img-fluid">
                                <h3><a href="#">Bodywavy</a></h3>
                            </div>
                        </div>
                        <div class="col-md-6 col-xs-6">
                            <div class="whs_short">
                                <img src="assets/images/product/product2.jpg" alt="" class="img-fluid">
                                <h3><a href="#">13"4 Frontals</a></h3>
                            </div>
                        </div>
                        <div class="col-md-12 col-xs-12">
                            <div class="s_more">
                                <a href="#">See More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4 col-xs-12">
                    <div class="col hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__zoomIn">
                        <h2 class="box-heading ">Hot Wigs</h2>
                    </div>           
                    <div class="col  hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__zoomIn">
                        <div class="row">
                            <div class="col-md-6 col-xs-6">
                                <div class="whs_short">
                                    <img src="assets/images/product/product2.jpg" alt="" class="img-fluid">
                                    <h3><a href="#">4"4 Closure Wigs</a></h3>
                                </div>
                            </div>
                            <div class="col-md-6 col-xs-6">
                                <div class="whs_short">
                                    <img src="assets/images/product/product15.jpg" alt="" class="img-fluid">
                                    <h3><a href="#">4"4 Closures</a></h3>
                                </div>
                            </div>
                            <div class="col-md-6 col-xs-6">
                                <div class="whs_short">
                                    <img src="assets/images/product/product15.jpg" alt="" class="img-fluid">
                                    <h3><a href="#">Bodywavy</a></h3>
                                </div>
                            </div>
                            <div class="col-md-6 col-xs-6">
                                <div class="whs_short">
                                    <img src="assets/images/product/product2.jpg" alt="" class="img-fluid">
                                    <h3><a href="#">13"4 Frontals</a></h3>
                                </div>
                            </div>
                            <div class="col-md-12 col-xs-12">
                                <div class="s_more">
                                    <a href="#">See More</a>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>

            <div class="col-md-4 col-xs-12">
                <div class="col hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__zoomIn">
                    <h2 class="box-heading ">Sample Kit</h2>
                </div>           
                <div class="col hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__zoomIn">
                    <div class="row">
                        <div class="col-md-6 col-xs-6">
                            <div class="whs_short">
                                <img src="assets/images/product/product2.jpg" alt="" class="img-fluid">
                                <h3><a href="#">4"4 Closure Wigs</a></h3>
                            </div>
                        </div>
                        <div class="col-md-6 col-xs-6">
                            <div class="whs_short">
                                <img src="assets/images/product/product15.jpg" alt="" class="img-fluid">
                                <h3><a href="#">4"4 Closures</a></h3>
                            </div>
                        </div>
                        <div class="col-md-6 col-xs-6">
                            <div class="whs_short">
                                <img src="assets/images/product/product15.jpg" alt="" class="img-fluid">
                                <h3><a href="#">Bodywavy</a></h3>
                            </div>
                        </div>
                        <div class="col-md-6 col-xs-6">
                            <div class="whs_short">
                                <img src="assets/images/product/product2.jpg" alt="" class="img-fluid">
                                <h3><a href="#">13"4 Frontals</a></h3>
                            </div>
                        </div>
                        <div class="col-md-12 col-xs-12">
                            <div class="s_more">
                                <a href="#">See More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>    
    </div>
</section>
<!--Wholesale Hot Wigs sample Kit-->

<!--Wholesale Sample Kit-->
<!--<section class="padding-y hom_wholesale_samplekit">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-xs-12">
                <div class="col hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__zoomIn">
                    <h2 class="box-heading ">Wholesale Deal</h2>
                </div>
                    
                <div class="col  hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__zoomIn">
                    <owl-carousel-o [options]="productWholesale" class="row">
                        <ng-template class="slide" carouselSlide *ngFor="let product of products">
                        <app-product-carousel [product]="product"></app-product-carousel>
                        </ng-template>
                    </owl-carousel-o>
                    <div class="s_more">
                        <a href="#">See More</a>
                    </div>
                    
                </div>
            </div>

            <div class="col-md-6 col-xs-12">
                    <div class="col hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__zoomIn">
                        <h2 class="box-heading ">Sample Kit's</h2>
                    </div>           
                    <div class="col  hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__zoomIn">
                        <owl-carousel-o [options]="productSamplekit" class="row">
                            <ng-template class="slide" carouselSlide *ngFor="let product of products">
                            <app-product-carousel [product]="product"></app-product-carousel>
                            </ng-template>
                        </owl-carousel-o>
                        <div class="s_more">
                            <a href="#">See More</a>
                        </div>
                    </div>
            </div>

        </div>    
    </div>
</section>-->
<!--Wholesale Sample Kit-->

<section class="testimonial padding-y">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center hide-on-init animate__faster"  animateOnScroll animationName="animate__animated animate__zoomIn">
                <h2>Customer Testimonials</h2>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-12 hide-on-init animate__faster"  animateOnScroll animationName="animate__animated animate__fadeInLeft">
                <app-testimonial></app-testimonial>
            </div>
        </div>
    </div>
</section>

<section class="blog-section padding-y">
    <div class="container">
        <div class="row">
            <div class="col text-center hide-on-init animate__faster"  animateOnScroll animationName="animate__animated animate__zoomIn">
                <h2>Latest From The Blog</h2>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col hide-on-init animate__faster" animateOnScroll animationName="animate__animated animate__fadeInLeft">
                <owl-carousel-o [options]="blogOption" class="row">
                    <ng-template class="slide" carouselSlide *ngFor="let post of posts">
                       <app-post-grid [post]="post"></app-post-grid>
                    </ng-template>
                </owl-carousel-o>
                
            </div>                        
        </div>        
    </div>
</section>