<header class="section-header header-dark" (scroll)="onWindowScroll($event);" (resize)="onResize($event)">
    <nav id="main-menu" class="navbar navbar-main navbar-expand-lg navbar-light bg-white">
      <div class="container">
        <ul class="nav d-lg-none">
            <li class="nav-item">
              <button class="navbar-toggler" type="button" (click)="toggleMenu(collapseMenu)" >
              <!-- <span class="navbar-toggler-icon"></span> -->
              <span class="fa fa-list-ul"></span>
            </button>
            </li>
            <li class="nav-item ml-2">
              <a href="#"><img src="assets/images/svg/follower.svg" class="img-xs img-20"/></a>
            </li>
           
        </ul>
        

        <a class="navbar-brand logo-top" (click)="setTitle('')" routerLink="/">
           <img src="assets/images/sgihair-logo.jpg" alt="{{ title }}" class="logo img-fluid dark-logo d-block">
           <img src="assets/images/white-sgihair-logo.png" alt="{{ title }}" class="logo img-fluid white-logo d-none">
        </a>
        <ul class="nav d-lg-none">
            <li class="nav-item">
              <button class="search" type="button" >
                <!-- <span class="navbar-toggler-icon"></span> -->
                <img src="assets/images/svg/search-interface-symbol.svg" class="img-xs img-20"/>
              </button>
            </li>
            <li class="nav-item ml-2">
              <a href="#"><img src="assets/images/svg/online-shop.svg" class="img-xs img-28"/></a>
            </li>
           
        </ul>
        <div class="collapse navbar-collapse" #collapseMenu>
          <button class="close" type="button" (click)="close(collapseMenu)" >
            <!-- <span class="navbar-toggler-icon"></span> -->
            <span class="fa fa-times"></span>
          </button>
          <!--<ul class="navbar-nav navbar-nav-main ml-auto mr-sm-2 mt-2 mt-lg-0">
            <li class="nav-item active mr-3">
              <a class="nav-link" href="#"><i class="fa fa-home"></i> <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item mr-3">
              <a class="nav-link" href="#">Our Dna</a>
            </li>
            <li class="nav-item dropdown mr-3" ngbDropdown>
              <a class="nav-link dropdown-toggle" href="#" role="button" ngbDropdownToggle>
                Shop by Category
              </a>
              <ul class="dropdown-menu" ngbDropdownMenu>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Machine Weft</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Bulk Hair</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Lace Closures</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Silk Base Closures</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Lace Frontal's</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Full Lace Wig's</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Front Lace wig's</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Clip in hair extension</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>360 Lace Frontals</a></li>
              </ul>
            </li>
            <li class="nav-item mr-3">
              <a class="nav-link" href="#">Offers</a>
            </li>
            <li class="nav-item mr-3">
              <a class="nav-link" href="#">Gallery</a>
            </li>
            <li class="nav-item mr-3">
              <a class="nav-link" href="#">Help</a>
            </li>
            <li class="nav-item mr-3">
              <a class="nav-link" href="#">Blog</a>
            </li>
            <li class="nav-item mr-3">
              <a class="nav-link" href="#">Contact Us</a>
            </li>
          </ul>-->
          <ul class="navbar-nav navbar-nav-main ml-auto mr-sm-2 mt-2 mt-lg-0">
           
            <li class="nav-item active dropdown mr-3" ngbDropdown>
              <a class="nav-link dropdown-toggle fst_icon" href="#" role="button" ngbDropdownToggle>
                <i class="fa fa-bars"></i> All Categories<span class="sr-only">(current)</span>
              </a>
              <ul class="dropdown-menu" ngbDropdownMenu>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Machine Weft</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Bulk Hair</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Lace Closures</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Lace Frontal's</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Full Lace Wig's</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Front Lace wig's</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>U Part wig's</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Clip in hair extensions</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Keratin hair extensions</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Mes topuee</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Hair Serum</a></li>
              </ul>
            </li>
            <li class="nav-item dropdown mr-3" ngbDropdown>
              <a class="nav-link dropdown-toggle" href="#" role="button" ngbDropdownToggle>
                Promotion
              </a>
              <ul class="dropdown-menu" ngbDropdownMenu>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Wholesale Deal</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Clearance Sale</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Sample Kit</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Coupon Code</a></li>
              </ul>
            </li>
            <li class="nav-item dropdown mr-3" ngbDropdown>
              <a class="nav-link dropdown-toggle" routerLink="/about-us" role="button" ngbDropdownToggle>
                About
              </a>
              <ul class="dropdown-menu" ngbDropdownMenu>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>The Company</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Hair Sources</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Factory</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Our Commitment</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Gift a Smile</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Memberships</a></li>
              </ul>
            </li>
            <li class="nav-item dropdown mr-3" ngbDropdown>
              <a class="nav-link dropdown-toggle" href="#" role="button" ngbDropdownToggle>
                Professionals
              </a>
              <ul class="dropdown-menu" ngbDropdownMenu>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Get Price List</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Become Affiliates</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Brand Partnership</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Private Label</a></li>
              </ul>
            </li>
            <li class="nav-item dropdown mr-3" ngbDropdown>
              <a class="nav-link dropdown-toggle" href="#" role="button" ngbDropdownToggle>
                Support
              </a>
              <ul class="dropdown-menu" ngbDropdownMenu>
                <li><a class="dropdown-item" routerLink="/faq" ngbDropdownItem >FAQs</a></li>
                <li><a class="dropdown-item" routerLink="/contact-us" ngbDropdownItem>Contact Us</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>My Account</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Order Status</a></li>
              </ul>
            </li>
            <li class="nav-item dropdown mr-3" ngbDropdown>
              <a class="nav-link dropdown-toggle" href="#" role="button" ngbDropdownToggle>
                Blog
              </a>
              <ul class="dropdown-menu" ngbDropdownMenu>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Industry News</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Hair Fashion</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Hair Care & Advice</a></li>
                <li><a class="dropdown-item" href="#" ngbDropdownItem>Company News</a></li>
              </ul>
            </li>
            
          </ul>
    
          <ul class="navbar-nav">
            <li class="nav-item m-r10"><a href="#" class="nav-link"><i class="fas fa-search"></i></a> </li>
            <li class="nav-item dropdown" ngbDropdown>
              <a class="nav-link dropdown-toggle" href="#" role="button" id="dropdownConfig" ngbDropdownToggle>
                <i class="fa fa-user rounded-circle mr-2"></i> Hello Sign in 
              </a>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownConfig" ngbDropdownMenu>
                <a class="dropdown-item" href="#" ngbDropdownItem>Sign in</a>
                <a class="dropdown-item" href="#" ngbDropdownItem>Create account</a>
                
              </div>
            </li>
            <li><a href="#" class="ml-md-3 btn btn-outline-warning"><i class="fas fa-shopping-bag"></i> cart (0)<small>items</small></a></li>
          </ul>
          
        </div>
      </div>
    </nav>
    </header><!-- section-header.// -->
