import { Component, Input, OnInit } from '@angular/core';
import { SlidesOutputData, OwlOptions } from 'ngx-owl-carousel-o';
import { Product } from '../product/product.model';
import { Post } from '../blog/post.model'
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
	active = 1;
	products: Product[] = [
    { id: 1, name: 'Body Wavy', price: 1500.00, special_price: 1200, url: '/product/:id', category_name: 'Machine Weft', rating: 5, img_src: 'assets/images/product/product2.jpg', description: '' },
    { id: 2, name: 'Wavy', price: 1500.00, special_price: 1200, url: '/product/:id', category_name: 'Machine Weft', rating: 5, img_src: 'assets/images/product/product15.jpg', description: '' },
    { id: 3, name: 'Straight', price: 1500.00, special_price: 1200, url: '/product/:id', category_name: 'Machine Weft', rating: 5, img_src: 'assets/images/product/product2.jpg', description: '' },
    { id: 4, name: 'Curly', price: 1500.00, special_price: 1200, url: '/product/:id', category_name: 'Machine Weft', rating: 5, img_src: 'assets/images/product/product15.jpg', description: '' },
    { id: 5, name: 'DeepWavy', price: 1500.00, special_price: 1200, url: '/product/:id', category_name: 'Machine Weft', rating: 5, img_src: 'assets/images/product/product2.jpg', description: '' },
    { id: 6, name: 'Loose Curly', price: 1500.00, special_price: 1200, url: '/product/:id', category_name: 'Machine Weft', rating: 5, img_src: 'assets/images/product/product15.jpg', description: '' },
    { id: 7, name: 'Straight', price: 1500.00, special_price: 1200, url: '/product/:id', category_name: 'Bulk Hair', rating: 5, img_src: 'assets/images/product/product2.jpg', description: '' },
  ]
	
	
  	productTabOptions: OwlOptions = {
	    loop: true,
	    autoplay:false,
	    mouseDrag: false,
	    touchDrag: false,
	    pullDrag: false,
	    dots: false,
	    navSpeed: 700,
	    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
	    nav: true,
	    responsive: {
	      0: {
	        items: 3
	      },
	      400: {
	        items: 3
	      },
	      740: {
	        items: 4
	      },
	      940: {
	        items: 4
	      }
	    },
	   
  	}

  	productTabOptions4: OwlOptions = {
	    loop: true,
	    autoplay:false,
	    mouseDrag: false,
	    touchDrag: false,
	    pullDrag: false,
	    dots: false,
	    navSpeed: 700,
	    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
	    nav: true,
	    responsive: {
	      0: {
	        items: 3
	      },
	      400: {
	        items: 3
	      },
	      740: {
	        items: 4
	      },
	      940: {
	        items: 4
	      }
	    },
	   
  	}
  	
  	productFeature: OwlOptions = {
	    loop: true,
	    autoplay:false,
	    mouseDrag: false,
	    touchDrag: false,
	    pullDrag: false,
	    dots: false,
	    navSpeed: 700,
	    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
	    nav: true,
	    responsive: {
	      0: {
	        items: 3
	      },
	      400: {
	        items: 3
	      },
	      740: {
	        items: 4
	      },
	      940: {
	        items: 4
	      }
	    },
	   
  	}

	productBestseller: OwlOptions = {
	    loop: true,
	    autoplay: true, 
	    mouseDrag: false,
	    touchDrag: false,
	    pullDrag: false,
	    dots: true, 
	    navSpeed: 700,
	    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
	    nav: false,  
	    responsive: {
	      0: {
	        items: 3
	      },
	      400: {
	        items: 3
	      },
	      740: {
	        items: 4
	      },
	      940: {
	        items: 6
	      }
	    },
	   
  	}

	productPopular: OwlOptions = {
	    loop: true,
	    autoplay:true, //false
	    mouseDrag: false,
	    touchDrag: false,
	    pullDrag: false,
	    dots: false, 
	    navSpeed: 700,
	    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
	    nav: false, 
	    responsive: {
	      0: {
	        items: 2
	      },
	      400: {
	        items: 3
	      },
	      740: {
	        items: 6
	      },
	      940: {
	        items: 6 
	      }
	    },
	   
  	}  
	  
	productHotwig: OwlOptions = {
	    loop: true,
	    autoplay:true, //false
	    mouseDrag: false,
	    touchDrag: false,
	    pullDrag: false,
	    dots: false,
	    navSpeed: 700,
	    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
	    nav: false,
	    responsive: {
	      0: {
	        items: 2
	      },
	      400: {
	        items: 3
	      },
	      740: {
	        items: 3
	      },
	      940: {
	        items: 3
	      }
	    },
	   
  	}
	  
	productWholesale: OwlOptions = {
	    loop: true,
	    autoplay:true, //false
	    mouseDrag: false,
	    touchDrag: false,
	    pullDrag: false,
	    dots: false,
	    navSpeed: 700,
	    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
	    nav: false,
	    responsive: {
	      0: {
	        items: 2
	      },
	      400: {
	        items: 3
	      },
	      740: {
	        items: 3
	      },
	      940: {
	        items: 3
	      }
	    },
	   
  	}
	  
	productSamplekit: OwlOptions = {
	    loop: true,
	    autoplay:true, //false
	    mouseDrag: false,
	    touchDrag: false,
	    pullDrag: false,
	    dots: false,
	    navSpeed: 700,
	    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
	    nav: false,
	    responsive: {
	      0: {
	        items: 2
	      },
	      400: {
	        items: 3
	      },
	      740: {
	        items: 3
	      },
	      940: {
	        items: 3
	      }
	    },
	   
  	}  

  	posts : Post [] = [
		{
			id:1, 
			post_name: 'Autam nuella natae', 
			post_url: '/', 
			post_date: new Date("January 18, 2020"),
			post_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tincidunt nunc lorem, nec faucibus mi facilisis eget.",
			post_img: 'assets/images/blog-news/blog-13-1-570x380.jpg'
		},
		{
			id:2, 
			post_name: 'Tuemin quides mivos', 
			post_url: '/', 
			post_date: new Date("January 18, 2020"),
			post_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tincidunt nunc lorem, nec faucibus mi facilisis eget.",
			post_img: 'assets/images/blog-news/blog-14-570x380.jpg'
		},
		{
			id:3, 
			post_name: 'Sualim utered nosum', 
			post_url: '/', 
			post_date: new Date("January 18, 2020"),
			post_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tincidunt nunc lorem, nec faucibus mi facilisis eget.",
			post_img: 'assets/images/blog-news/blog-15-1-570x380.jpg'

		},
		{
			id:4, 
			post_name: 'Emin quides mivos', 
			post_url: '/', 
			post_date: new Date("January 18, 2020"),
			post_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tincidunt nunc lorem, nec faucibus mi facilisis eget.",
			post_img: 'assets/images/blog-news/blog-14-570x380.jpg'
		}
	];

	blogOption: OwlOptions = {
	    loop: true,
	    autoplay:true, //false
	    mouseDrag: true, //false
	    touchDrag: true, //false
	    pullDrag: false,
	    dots: false,
	    navSpeed: 700,
	    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
	    nav: false, //true
	    responsive: {
	      0: {
	        items: 1
	      },
	      400: {
	        items: 1
	      },
	      740: {
	        items: 3
	      },
	      940: {
	        items: 3
	      }
	    },
	   
  	}
  	component: SlidesOutputData;

  	//activeSlides: SlidesOutputData;
  	constructor() { }

 	ngOnInit(): void {
  	}

  	getData(data: SlidesOutputData) {
        this.component = data;
        //console.log(this.component);
  	}
  	// getPassedData(data: SlidesOutputData) {
   //  	this.activeSlides = data;
   //  	console.log(this.activeSlides);
  	// }

}
