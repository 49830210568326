import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent implements OnInit {

	testimonialSliderOptions: OwlOptions = {
	    loop: true,
	    autoplay:false,
	    mouseDrag: false,
	    touchDrag: false,
	    pullDrag: false,
	    dots: true,
	    navSpeed: 700,
	    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
	    nav: false,
	    responsive: {
	      0: {
	        items: 1
	      },
	      400: {
	        items: 2
	      },
	      740: {
	        items: 3
	      },
	      940: {
	        items: 3
	      }
	    },
  	}
  constructor() { }

  ngOnInit(): void {
  }

}
