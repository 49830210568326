import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	public isCollapsed = true;
  	public toggle( element: HTMLElement) {
	  element.classList.toggle('show');
	}
	windowScrolled: boolean;
  	constructor() { }

  	ngOnInit(): void {
  	}

  	@HostListener("window:scroll", [])
  	onWindowScroll() {
	    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 150) {
	      this.windowScrolled = true;
	    }
	    else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
	      this.windowScrolled = false;
	    }
  	}

  	backToTop() {
	    (function smoothscroll() {
	      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
	      if (currentScroll > 0) {
	        window.requestAnimationFrame(smoothscroll);
	        window.scrollTo(0, currentScroll - (currentScroll / 8));
	      }
	    })();
  	} 

}
