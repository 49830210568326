import { Component, EventEmitter, Input, OnInit, HostListener, Inject, Output, OnDestroy } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [NgbDropdownConfig]
})
export class HeaderComponent implements OnInit {
  title = 'SGI Hair';
  public isMenuCollapsed = true;
  public screenWidth: any;
  public screenHeight: any;

  constructor(private titleService: Title, config: NgbDropdownConfig) {
    config.placement = 'right';
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    console.log("width :", this.screenWidth + "\n Height :", this.screenHeight);
  }

  @HostListener('window:scroll', ['$event'])
  @HostListener('window:resize', ['$event'])

  onWindowScroll(e) {
     if (window.pageYOffset > 150) { //550
       let element = document.getElementById('main-menu');
       element.classList.add('fixed-top');
       let darklogo = document.getElementsByClassName('dark-logo');
       darklogo[0].classList.remove('d-block');       
       darklogo[0].classList.add('d-none');
       let whitelogo = document.getElementsByClassName('white-logo');
        whitelogo[0].classList.remove('d-none');       
        whitelogo[0].classList.add('d-block');
     } 
     else {
        let element = document.getElementById('main-menu');
        element.classList.remove('fixed-top'); 
        let whitelogo = document.getElementsByClassName('white-logo');
        whitelogo[0].classList.remove('d-block');       
        whitelogo[0].classList.add('d-none');
        let darklogo = document.getElementsByClassName('dark-logo');
        darklogo[0].classList.remove('d-none');       
        darklogo[0].classList.add('d-block');
     }
  }
  toggleMenu(ele : HTMLElement){
    ele.classList.toggle('show');
  }
  close(ele : HTMLElement){
    ele.classList.remove('show');
  }
  onResize(event) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    console.log("width :", this.screenWidth + "\n Height :", this.screenHeight);
  }

  setTitle(newTitle: string) {
    var title = '';
    if (newTitle != '') {
      title = newTitle + ' | ';
    }
    this.titleService.setTitle(title + this.title);
  }

}
