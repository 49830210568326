import { Component, OnInit } from '@angular/core';
import { Post } from './post.model'
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

	post : Post [] = [
		{id:1, post_name: 'Autam nuella natae', post_url: '/'},
		{id:2, post_name: 'Tuemin quides mivos', post_url: '/'},
		{id:3, post_name: 'Sualim utered nosum', post_url: '/'}
	];
	
  	constructor() { }

  	ngOnInit(): void {
  	}

}
