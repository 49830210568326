import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {

  public toggle( element: HTMLElement) {
	  element.classList.toggle('show');
	}
 
  constructor() {}
  
  ngOnInit(): void {
  }

}
