<div class="col blog-grid">
		<article class="post-wrapper blog-post border">			
	        <div class="post-thumb">
	        	<figure class="figure">
				  	<img src="{{post.post_img}}" class="figure-img img-fluid rounded" alt="">
				</figure>	           
	        </div>
	        <div class="post-content">
	            <div class="post-date mb-2">
	                <i class="far fa-calendar-alt"></i> {{post.post_date | date:'d MMMM yyyy' : 'UTC'}}
	            </div>
	            <div class="post-title mb-2">
	                <h4>
	                    <a href="#" title="">{{post.post_name}}</a>
	                </h4>
	            </div> 
	            <div class="post-description mb-2">
	                {{post.post_description}}
	            </div>
	            <div class="button">
	            	<a href="" class="filled-outline text-black">Read More</a>
	            </div>
	        </div>
		   
		</article>	    
	</div>