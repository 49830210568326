import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'sgihair';

  routerSubscription: Subscription;
  
  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
    ){}
  ngOnInit() {
    this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                // Show loading indicator
            }

            if (event instanceof NavigationEnd) {
                // Hide loading indicator
                if (isPlatformBrowser(this.platformId)) {
                  this.scrollPageToTop();
                }
            }

            if (event instanceof NavigationError) {
                // Hide loading indicator

                // Present error to user
                console.log(event.error);
            }
        });
      //   this.router.events
      // .pipe(filter(e => e instanceof NavigationEnd))
      // .subscribe((e: NavigationEnd) => {
      //   if (isPlatformBrowser(this.platformId)) {
      //     this.scrollPageToTop();
      //   }
      //   // this.addMetaInfo();
        
      // });
  }
  scrollPageToTop() {
    window.scroll(0, 0);
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }
}
