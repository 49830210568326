import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DefaultComponent } from './layout/default/default.component';
//import { CategoryComponent } from './category/category.component';
import { ProductComponent } from './product/product.component';
// import { AboutComponent } from './pages/about/about.component';
// import { ContactComponent } from './pages/contact/contact.component';
// import { FaqComponent } from './pages/faq/faq.component';

const routes: Routes = [
  {
    path: '',
    component: DefaultComponent,
    children: [
      { path: '', component: HomeComponent },
      // { path: 'about-us', component: AboutComponent },
      // { path: 'contact-us', component: ContactComponent },
      //{ path: 'category', component: CategoryComponent },      
      { path: 'product/:id', component: ProductComponent },
      {
        path: '',
        loadChildren: () =>
          import('./category/category.module').then((m) => m.CategoryModule),
        data: { preload: true, delay: false },
      },
      {
        path: '',
        loadChildren: () =>
          import('./pages/pages.module').then((m) => m.PagesModule),
      },
      // { path: 'faq', component: FaqComponent },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      initialNavigation: 'enabled',
    }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
