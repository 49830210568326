<footer class="footer-area">
    <div class="footer-top padding-y">
        <div class="container">
            <div class="row">
                <div class="col-md-3">
                    <div class="footer-widget">
                        <h2 class="title">Sgi Indian Human Hair Exporter</h2>
                        <div class="txt_icn">
                            <div class="icn">
                                <i class="fa fa-map-marker-alt"></i>
                            </div>
                            <div class="txt">
                                SGI Hair<br/>
                                S Mint St, Kondithope,<br/>
                                George Town, Chennai - 600001.<br/>
                                Tamil Nadu, India
                            </div>
                        </div>
                        <div class="txt_icn">
                            <div class="icn">
                                <i class="icon fa fa-phone-alt"> </i>
                            </div>
                            <div class="txt">
                                <a href="tel:+9198843 33534">(+91) 98843 33534</a>
                            </div>
                        </div>
                        <div class="txt_icn">
                            <div class="icn">
                                <i class="icon fa fa-envelope"> </i>
                            </div>
                            <div class="txt">
                                <a href="mailto:india@sgihair.com">india@sgihair.com</a>
                            </div>
                        </div>
                        <!--<address>
                            <dl>
                                <dt>SGI Hair</dt>
                                <dd>S Mint St, Kondithope,</dd>
                                <dd>George Town, Chennai - 600001.</dd>
                                <dd>Tamil Nadu,</dd>
                                <dd>India</dd>
                            </dl>
                        </address>
                        <ul class="list-icon">
                            <li><a href="tel:+9198843 33534"><i class="icon fa fa-phone-alt"> </i> (+91) 98843 33534</a> </li>
                            <li><a href="mailto:india@sgihair.com"><i class="icon fa fa-envelope"> </i>india@sgihair.com</a></li>

                        </ul>-->
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="footer-widget">
                        <h5 class="title">Remy Hair Exporter (India)</h5>
                        <ul class="list-inline footer-menu">
                            <li><a routerLink="/">Indian human hair factory</a></li>
                            <li><a routerLink="/">About SGI Hair Factory Chennai</a></li>
                            <li><a routerLink="/">Human Hair Extensions</a></li>
                            <li><a routerLink="/">Contact Wholesale Indian Hair</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="footer-widget">
                        <h5 class="title">Quick Links</h5>
                        <ul class="list-unstyled footer-menu">
                            <li><a routerLink="/">Privacy and Terms</a></li>
                            <li><a routerLink="/">Timeline & Delivery</a></li>
                            <li><a routerLink="/">Hair Care Products</a></li>
                            <li><a routerLink="/">FAQ</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="footer-widget">
                        <h5 class="title">Questions? We Will Reply In 15 Minutes!</h5>
                        <!-- <a (click)="toggle(whatsapp)" class="collape-whats-app text-center"> <span class="icon-xs bg-white whtapp"><i class="fab fa-whatsapp"></i></span> <span class="wht-txt">Start Chat in whatsapp</span> <i class="fa fa-chevron-down icon-right"></i></a> -->
                        <a (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="whatsapp" class="collape-whats-app text-center"> 
                            <span class="icon-xs bg-white whtapp"><i class="fab fa-whatsapp"></i></span> 
                            <span class="wht-txt">Start Chat in whatsapp</span> 
                            <i class="fa fa-chevron-down icon-right"></i>
                        </a>
                        <div class="collapse bg-white px-2 py-2" id="whatsapp" [ngbCollapse]="isCollapsed" >
                            <div class="whatsap_num">
                                <a target="_blank" href="https://api.whatsapp.com/send?phone=919884333534">MR.ASHOK KUMAR : +919884333534</a>
                            </div>
                            
                        </div>
                        <div class="footer-rating">
                             <p class="mt-3">Sgi Indian Human Hair Exporters <span class="clearfix"></span><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i> 4.9 out of 5 based on 5715 ratings.</p>
                        </div>
                       <div class="newsletter">
                            <form method="post">
                                <div class="form-group required">
                                    <label class="col-sm-2 control-label">Enter Your Email</label>
                                    <div class="input-news">
                                      <input type="email" name="txtemail" id="txtemail" value="" placeholder="Enter Your Email" class="form-control input-lg">    
                                    <button type="submit" class="btn btn-default btn-lg" onclick="return subscribe();">Subscribe</button>  
                                    </div>
                                </div>
                                </form>
                        </div>
                        <ul class="nav social-icon mt-3">
                            <li class="facebook"><a href="https://www.facebook.com/sgihair" class="icon-xs"><i class="fab fa-facebook"></i></a></li>
                            <li class="vk"><a href="https://vk.com/sgihair" class="icon-xs"><i class="fab fa-vk"></i></a></li>
                            <li class="twitter"><a href="https://twitter.com/sgihair" class="icon-xs"><i class="fab fa-twitter"></i></a></li>
                            <li class="youtube"><a href="https://www.youtube.com/user/sgihair/user" class="icon-xs"><i class="fab fa-youtube"></i></a></li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom-area">
        <div class="container">
            <div class="row">
                <div class="col-md-6 footer-bottom">
                    <p class="copy-right"><a routerLink="/">SGI Indian Human Hair Exporters</a> © 2008 - 2022 | All Rights Reserved.</p>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="back-to-top">
    <a (click)="backToTop()" id="back-to-top" [ngClass]="{'show-back-to-top': windowScrolled}"><i class="fa fa-angle-double-up"></i></a>
</div>
<div id="bottom-toolbar" class="bottom-toolbar">
    <div class="bottom-navigation">
        <ul class="nav">
            <li class="nav-item">
                <a href="">
                    <i class="icon-svg icon-home"></i>
                    <span>Home</span>
                </a>
            </li>
            <li class="nav-item">
                <a href="">
                   <i class="icon-svg icon-categories"></i>
                    <span>Categories</span>
                </a>
            </li>
            <li class="nav-item">
                <a href="">
                    <i class="icon-svg icon-deals"></i>
                    <span>Deals</span>
                </a>
            </li>
            <li class="nav-item">
                <a href="">
                    <i class="icon-svg icon-help"></i>
                    <span>Help</span>
                </a>
            </li>
            <li class="nav-item">
                <a href="">
                    <i class="icon-svg icon-support"></i>
                    <span>Support</span>
                </a>
            </li>
        </ul>
    </div>
</div>
