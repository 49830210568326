import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
/*import { Ng2PageScrollModule } from 'ng2-page-scroll';*/
import { AccordionModule } from 'ngx-bootstrap/accordion';
/*import { BreadcrumbModule } from 'xng-breadcrumb';*/
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
//import { CategoryComponent } from './category/category.component';
import { ProductComponent } from './product/product.component';
import { ProductListComponent } from './product/product-list/product-list.component';
import { ViewComponent } from './product/view/view.component';
import { DefaultComponent } from './layout/default/default.component';
import { BlogComponent } from './blog/blog.component';
import { CarouselHolderComponent } from './carousel-holder/carousel-holder.component';
import { PostGridComponent } from './blog/post-grid/post-grid.component';
import { TestimonialComponent } from './carousel-holder/testimonial/testimonial.component';
import { ProductCarouselComponent } from './product/product-carousel/product-carousel.component';
import { TopbarComponent } from './header/topbar/topbar.component';
//import { FaqComponent } from './pages/faq/faq.component';
//import { AboutComponent } from './pages/about/about.component';
//import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    //CategoryComponent,
    ProductComponent,
    ViewComponent,
    DefaultComponent,
    BlogComponent,
    CarouselHolderComponent,
    ProductListComponent,
    PostGridComponent,
    TestimonialComponent,
    ProductCarouselComponent,
    TopbarComponent,
    //FaqComponent,
    //AboutComponent, 
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule,
    CarouselModule,
    AnimateOnScrollModule.forRoot(),
    NgxPageScrollCoreModule,
    NgxPageScrollModule,
    /*Ng2PageScrollModule,*/
    AccordionModule.forRoot()
    /*BreadcrumbModule*/
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
