<div class="row">
    <div class="col-md-3 col-sm-6" *ngIf='product'>
        <div class="product-grid">
            <div class="product-image">
                <a routerLink="/product/{{product.id}}">
                    <img class="pic-1" src="{{product.img_src}}" class="img-fluid">
                    <img class="pic-2" src="http://bestjquery.com/tutorial/product-grid/demo9/images/img-2.jpg">
                </a>
                <ul class="social">
                    <li><a href="" data-tip="Quick View"><i class="fa fa-search"></i></a></li>
                    <li><a href="" data-tip="Add to Wishlist"><i class="fa fa-shopping-bag"></i></a></li>
                    <li><a href="" data-tip="Add to Cart"><i class="fa fa-shopping-cart"></i></a></li>
                </ul>
                <span class="product-new-label">Sale</span>
                <span class="product-discount-label">20%</span>
            </div>
            
            <div class="product-content">
                <h3 class="title"><a href="#">{{ product.name}}</a></h3>
                <ul class="rating list-unstyled">
                    <li class="fa fa-star"></li>
                    <li class="fa fa-star"></li>
                    <li class="fa fa-star"></li>
                    <li class="fa fa-star"></li>
                    <li class="fa fa-star disable"></li>
                </ul>
                <div class="price-wrapper">
                    <span class="old-price">${{ product.special_price}}</span>
                    <span class="new-price">${{ product.price}}</span>
                </div>
                <a class="add-to-cart filled-outline" href="">+ Add To Cart</a>
            </div>
        </div>
    </div>
    
</div>


