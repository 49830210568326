<section class="topbar">
    <owl-carousel-o [options]="announcementSliderOptions">
        <ng-template carouselSlide>
            <p>Raw Indian Human Hair Exporters - Wholesale Virgin Temple Hair <a href="#">Shop now</a></p>
        </ng-template>
        <ng-template carouselSlide>
            <p>Free Shipping over $100 <a href="#">Shop now</a></p>
        </ng-template>    
        <ng-template carouselSlide>
            <p>100% Raw Indian Remy Human Hair <a href="#">Shop now</a></p>
        </ng-template>  
    </owl-carousel-o>
</section>