<owl-carousel-o [options]="testimonialSliderOptions" class="testimonial-slider">
    <ng-template carouselSlide class="slide">
    	<div class="testimonial-box">
            <div class="card">
                <div class="testimonial-quote"> 
                    <i class="fa fa-quote-left"></i>
                </div>
                <div class="testimonial-content">            
                    <p>I have been fallen into scam hair trap before but SGI hair is something l can swear by, my clients love it and keep coming back for more. Not to mention the customer care from Mr Kumar. Interacting with him was quite insightful, he Gives his clients full attention regardless of hi busy schedule.</p>
                </div>
                <div class="testimonial-img">
                    <img src="assets/images/testimonial/testimonial1.jpg" class="img-fluid"/>
                </div>
                <div class="testimonial-info">
                    <div class="testimonial-meta">
                        <h4>Wachira Grace</h4>
                        <!--<span>CEO Of Company</span>-->
                    </div>
                    <div class="testimonial-rating">
                        <ul class="rating list-unstyled">
                            <li class="fa fa-star"></li>
                            <li class="fa fa-star"></li>
                            <li class="fa fa-star"></li>
                            <li class="fa fa-star"></li>
                            <li class="fa fa-star"></li>
                        </ul>
                    </div>
                </div>

            </div>            
        </div>
        
    </ng-template>  
    <ng-template carouselSlide>
        <div class="testimonial-box">
            <div class="card">
                <div class="testimonial-quote"> 
                    <i class="fa fa-quote-left"></i>
                </div>
                <div class="testimonial-content">            
                    <p>Love, love, love the quality of SGI hair!! Will be ordering more wholesale hair in the near future!  I've found a great distributor! Highly recommend!!! </p>
                </div>

                <div class="testimonial-img">
                    <img src="assets/images/testimonial/testimonial2.jpg" class="img-fluid"/>
                </div>
                <div class="testimonial-info">
                    <div class="testimonial-meta">
                        <h4>Shatrina Johnson-Black</h4>
                        <!--<span>CEO Of Company</span>-->
                    </div>
                    <div class="testimonial-rating">
                        <ul class="rating list-unstyled">
                            <li class="fa fa-star"></li>
                            <li class="fa fa-star"></li>
                            <li class="fa fa-star"></li>
                            <li class="fa fa-star"></li>
                            <li class="fa fa-star"></li>
                        </ul>
                    </div>
                </div>
                
            </div>            
        </div>
    	
    </ng-template>  
    <ng-template carouselSlide>
        <div class="testimonial-box">
            <div class="card">
                <div class="testimonial-quote"> 
                    <i class="fa fa-quote-left"></i>
                </div>
                <div class="testimonial-content">            
                    <p>I normally don't post reviews but I felt compelled to post a review about SGI Hair. After seeking and contacting so many wholesale hair vendors, I became discouraged, disappointed and frustrated with the lack of customer service I was receiving...</p>
                </div>

                <div class="testimonial-img">
                    <img src="assets/images/testimonial/testimonial3.jpg" class="img-fluid"/>
                </div>
                <div class="testimonial-info">
                    <div class="testimonial-meta">
                        <h4>Chara Brazley</h4>
                        <!--<span>CEO Of Company</span>-->
                    </div>
                    <div class="testimonial-rating">
                        <ul class="rating list-unstyled">
                            <li class="fa fa-star"></li>
                            <li class="fa fa-star"></li>
                            <li class="fa fa-star"></li>
                            <li class="fa fa-star"></li>
                            <li class="fa fa-star disable"></li>
                        </ul>
                    </div>
                </div>
                
            </div>            
        </div>
    	
    </ng-template>
    <ng-template carouselSlide>
        <div class="testimonial-box">
            <div class="card">
                <div class="testimonial-quote"> 
                    <i class="fa fa-quote-left"></i>
                </div>
                <div class="testimonial-content">            
                    <p>OMG! I Love this authentic Indian hair! I received great customer service and quick delivery. You will not be disappointed. Raj was kind and made me feel comfortable that my order would arrive on time and accurate. Thanks SGI for having a great staff.</p>
                </div>
                <div class="testimonial-img">
                    <img src="assets/images/testimonial/testimonial4.jpg" class="img-fluid"/>
                </div>
                <div class="testimonial-info">
                    <div class="testimonial-meta">
                        <h4>Denise Murphy</h4>
                        <!--<span>CEO Of Company</span>-->
                    </div>
                    <div class="testimonial-rating">
                        <ul class="rating list-unstyled">
                            <li class="fa fa-star"></li>
                            <li class="fa fa-star"></li>
                            <li class="fa fa-star"></li>
                            <li class="fa fa-star"></li>
                            <li class="fa fa-star"></li>
                        </ul>
                    </div>
                </div>

            </div>            
        </div>
    	
    </ng-template>
    <ng-template carouselSlide>
        <div class="testimonial-box">
            <div class="card">
                <div class="testimonial-quote"> 
                    <i class="fa fa-quote-left"></i>
                </div>
                <div class="testimonial-content">            
                    <p>Excellent !!! Fantastic product with low price n best quality... Luv to use tis product n my customers also luved it... Hope to hv big deal in future...</p>
                </div>

                <div class="testimonial-img">
                    <img src="assets/images/testimonial/testimonial5.jpg" class="img-fluid"/>
                </div>
                <div class="testimonial-info">
                    <div class="testimonial-meta">
                        <h4>Madhu s</h4>
                        <!--<span>CEO Of Company</span>-->
                    </div>
                    <div class="testimonial-rating">
                        <ul class="rating list-unstyled">
                            <li class="fa fa-star"></li>
                            <li class="fa fa-star"></li>
                            <li class="fa fa-star"></li>
                            <li class="fa fa-star"></li>
                            <li class="fa fa-star"></li>
                        </ul>
                    </div>
                </div>
                
            </div>            
        </div>
    	
    </ng-template>
</owl-carousel-o>
